/* HOMEPAGE */
.boxes {
    @include make-row();

    padding: 30px;

    @media(min-width: $screen-sm-min) {
        position: absolute;
        margin-top: -350px;
        z-index: 300;
        padding: 30px;
        color: #fff;
        margin-bottom: 0;
    }


    .box {

        @include make-sm-column(3);

        margin-bottom: 30px;

        @media(min-width: $screen-sm-min) {
            margin-bottom: 0;
        }

        h3 {
            font-size: 18px;
            font-weight: 700;
            @media(min-width: $screen-sm-min) {
                color: #FFF;
                text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
            }
        }

        .box-text {
            font-size: 16px;

            @media(min-width: $screen-sm-min) {
                text-shadow: 0px 1px 2px rgba(0,0,0,0.5);

                a {
                    color: #FFF;
                }
            }
        }

        img {
            width: 100%;
        }
    }  
}


// SHADOW
.shadow {
    position:relative;
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3);

    &:after {
        content:"";
        position:absolute;
        z-index:-1;
        box-shadow:0 0 50px rgba(0,0,0,0.9);
        bottom:0px;
        left:10%;
        right:10%;
        width:80%;
        height:50%;
        border-radius:100%;
    }
}



// HERO
.hero {
    position: relative;
    width: 100%;
    height: 450px;
    background-size: 100% auto !important;
    
    @media(min-width: $screen-sm-min) {
        width: 760px;
        height: 505px;
    }
    
    @media(min-width: $screen-md-min) {
        width: 970px;
        height: 644px;
    }
    @media(min-width: $screen-lg-min) {
        width: 1170px;
        height: 777px;
    }
}


// PAGE CONTENT
.page-content {
    font-size: 16px;
    padding: 0 30px;
    margin-top: -200px;
    margin-bottom: 30px;
    background-size: 100% auto !important;

    @media(min-width: $screen-md-min){
        margin-top: -300px;
    }
}

//FLEXSLIDER 
.flexslider {
    background: transparent;
    border: 0 none;
}

.flex-control-paging li a {
    border-radius: 0 !important;
    box-shadow: none;
    background: #666;

    &.flex-active {
        background: #FFF;
    }
}

#page-carousel .flex-control-nav {
    top: -30px;
}

#home-carousel {
    position: relative;
    margin: 0;
}


// PAGE HEADER
.page-header {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 0;
    margin-top: 0;

    h1 {
        font-size: 16px;
        font-weight: 800;
        margin: 0;
        padding: 5px 0;
        text-transform: uppercase;

    }
}


//GALLERY
#gallery {
    margin-top: 30px;

    .gallery-item {
        margin-bottom: 30px;
    }
}

// MAP
#map {
    width: 100%;
    height: 240px;
    margin-bottom: 30px;
    margin-top: 30px;
    
    @media(min-width: $screen-md-min) {
        margin-top: 0;
    }
}
