// Grid system
.main {
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow: hidden;
  
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}