.content-info {
    
    .container {
        padding-left: 0;
        padding-right: 0;
        
    }
}

.copyright {
    background: #5d7e4c url('../images/footer-bg.jpg');
    padding: 30px;
    font-weight: 800;
    color: #FFF;
    text-align: center;
    margin-top: 30px;
    text-shadow: 0px 1px 2px rgba(0,0,0,0.7);
    
    @media(min-width:$screen-sm-min) {
      border-top: 1px solid #fff;
      margin-top: 0;
    }
    
    .widget {
        
        a {
            color: #FFF !important;
            text-decoration: underline;
        }
    }
}
