.top-banner {
    background: $brand-primary url('../images/header-bg.jpg');
    color: #000; 
    padding: 20px 0;
    overflow: hidden;
    
    a {
        color: #000;
    }
    
    .container {
        position: relative;
    }
}

.top-menu {
    text-align: center;
    
    @media(min-width: $screen-sm-min){
        position: absolute;
        right: 0;
        top: 0;
    }
    
    .top-menu-item {
        font-weight: 800;
        padding: 0 8px;
        border-right: 1px solid #000;
        
        &:last-child {
            border-right: 0 none;
        }
    }
}

.brand {
    display: block;
    background: url('../images/fromager-des-chaux-logo.png') no-repeat;
    background-size: 420px 160px;
    width: 420px;
    height: 160px;
    text-indent: -9999px;
}

.banner {
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    
    
    
    a {
        color: #fff;
        
        
        &:hover {
            color: $brand-primary;
        }
    }
}
